
import {useNavigate} from "react-router-dom";

const Intro = ({readQuestions, loaded}) => {
    const navigate = useNavigate();
    const handleButton = () => {
        readQuestions();
        navigate("/question/1", {replace: false})
    }
    
    return (
        <div className="main-banner" id="top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="header-text">
                <h6>Õppekeskus N.O.R.T</h6>
                <h2>Kas Sa oskad piisavalt HTML, CSS ja Javascripti, et osaleda Reacti algkursuses?<br />
                <em>Tee väike test et teada saada!</em></h2>
                <div className="main-button-gradient">
                  <div className="scroll-to-section">
                    <button className="main-action-button" onClick={handleButton}>
                        Alusta
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right-image">
                <img src="/assets/images/banner-right-image.png" alt=""/>
              </div>
            </div>
          </div>
        </div>
        <div className="row">

        </div>
      </div>
    )
}
export default Intro