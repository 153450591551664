import {useNavigate, Navigate} from "react-router-dom";
import Evaluation from "../components/Evaluation";

function Review({correctAnswers, loading, errorMsg}) {
    const navigate = useNavigate();

    if (errorMsg) {
        return <Navigate to="/error" />;
    }
    if (!correctAnswers && !loading) {
        console.log("No conclusion, no loading in Review")
        return <Navigate to="/" />;
    }
    if (loading) {
        return (
            <div>Loading ...</div>
        )
    }
    
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="left-image">
                        <img src="assets/images/about-left-image.png" alt=""/>
                    </div>
                </div>
                <div className="col-lg-6 align-self-center">
                    <div className="section-heading review">
                        <h6>Õppekeskus N.O.R.T. küsitlus</h6>
                        <h4>Õigeid vastuseid</h4>
                        <h4><em>{correctAnswers}</em></h4>
                        <Evaluation correctAnswers={correctAnswers} />
                        <div>
                            <p>Tahad proovida uuesti?</p>
                            <button
                                onClick={()=>navigate("/",{replace: true})} 
                                className="main-action-button"
                            >
                                Alusta testi uuesti
                            </button>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    )
}
export default Review