import MarkdownComponent from "./MarkdownComponent";

function VariantDescription({variant, revealeRemark}) {
    console.log("Variant", variant)
    if (variant.type === "mdtext") {
        return (
            <div>
                <MarkdownComponent content={variant.description}/>
                {revealeRemark? <MarkdownComponent content={variant.remark}/> : <></>}
            </div>
        )
    }
    return variant.description
}

export default VariantDescription