import {unified} from 'unified'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import rehypeStringify from 'rehype-stringify'
import {useState, useEffect} from 'react'

function MarkdownComponent({content}) {
    const [input, setInput] = useState('')
    async function _convertToHtml(markdownText) {
        console.log('input is, converting')
        console.log(markdownText)
        const resultAsHtml = await unified()
          .use(remarkParse)
          .use(remarkRehype)
          .use(rehypeStringify)
          .process(markdownText)
      
        console.log(String(resultAsHtml))
        setInput(resultAsHtml)
      }

    useEffect(()=> {
        _convertToHtml(content)
    }, [content])

    return (
        <div dangerouslySetInnerHTML={{__html: input}}  />
    )
}

export default MarkdownComponent