import {useParams, useNavigate, Navigate} from "react-router-dom";
import React, {useState, useEffect} from "react";
import MarkdownComponent from "../components/MarkdownComponent";
import VariantDescription from "../components/VariantDescription";

const Question = ({
    questions, answers, loaded, loading, submitAnswers, answerQuestion, errorMsg
}) => {

    const [questionFile, setQuestionFile] = useState('')
    const [currentQuestion, setCurrentQuestion] = useState({variants:[]})
    
    async function  loadMdFile(fileName) {
        const response = await fetch(fileName)
        const result = await response.text()
        console.log(result)
        setQuestionFile(result)
    }

    let { questionNo } = useParams();
    const currentIndex = parseInt(questionNo)-1;

    useEffect(() => {
        if (!questions || questions.length < 1) {
            return
        }
        const currentQuestion = questions[currentIndex];
        setCurrentQuestion({...currentQuestion})
        if (!currentQuestion) {
            return
        }
        if (currentQuestion.question.type === "mdfile") {
            loadMdFile(currentQuestion.question.content)
        }

    }, [questions, currentIndex])

    const navigate = useNavigate();
    if (errorMsg) {
        return <Navigate to="/error" />;
    }
    if (!loading && !loaded) {
        return <Navigate to="/" />;
    }
    if (loading || !currentQuestion) {
        return (
            <div>Loading ...</div>
        )
    }


    const nextQuestion = () => {
        navigate("/question/" + (currentIndex+2), {replace: false})
    }

    const previousQuestion = () => {
        navigate("/question/" + (currentIndex), {replace: false})
    }
    
    const markers = "ABCDEFGHIJKLMNOPQR"
    const currentAnswer = answers[currentQuestion.id] || null;
    const answersJsx = currentQuestion.variants.map((variant, index) => {
        let itemStyleClass = "info-item"
        let revealeRemark = false
        if (currentAnswer && variant.weight > 0) {
            itemStyleClass += ' correct-answer'
            revealeRemark = true
        } else if (currentAnswer === variant.id) {
            itemStyleClass += ' selected-answer'
            revealeRemark = true
        }
        return (
            <div 
                className={itemStyleClass} 
                key={variant.id}
                onClick={()=> {
                    if (!currentAnswer) {
                        answerQuestion(currentQuestion.id, variant.id)
                    }
                }}
            >
                <h4>{markers[index]}</h4>
                <VariantDescription variant={variant} revealeRemark ={revealeRemark}/>
            </div>            
        )
    })

    let firstButton = (
        <button onClick={previousQuestion} className="main-action-button">
            Eelmine
        </button>)
    if (currentIndex === 0) {
        firstButton = (
            <button 
                onClick={()=>navigate("/",{replace: false})} 
                className="main-action-button"
            >
                Restart
            </button>
        )
    }
    
    let secondButton = (
        <button 
            onClick={nextQuestion} 
            className="main-action-button"
            disabled={!currentAnswer}
        >
                Järgmine
        </button>
    )
    if (questions.length === (currentIndex+1)) {
        secondButton = (
            <button 
                onClick={()=> {
                    submitAnswers();
                    navigate("/conclusion", {replace: true})
                }} 
                className="main-action-button"
                disabled={!currentAnswer}
            >
                Lõpeta
            </button>
        )
    }

    let questionContent = currentQuestion.question?.content || 'Loading...'
    if (currentQuestion.question?.type === "mdfile") {
        questionContent = <MarkdownComponent content={questionFile} />
    }

    return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-10 col-md-9 col-lg-8 align-self-center">
                        <div className="section-heading">
                            <h6>Õppekeskus N.O.R.T. küsitlus</h6>
                            <h4>Küsimus <em>{questionNo}/{questions.length}</em></h4>
                            <div className="question-description">
                                {questionContent}
                            </div>

                        </div>
                        <section className="row get-info">
                            {answersJsx}
                        </section>
                    </div>   
                    <div className="col-12 question-action">
                        {firstButton}
                        {secondButton}
                    </div>             
                </div>
            </div>      
    )
}

export default Question;