function Evaluation({correctAnswers}) {
    if (correctAnswers < 4) {
        return (
            <div>
                Soovitame sul tulla veebiarenduse algkursusele. 
                Tutvu kursusega lähemalt <a href="https://nort.ee/koolitused/moodulkoolitus-baasreact/?utm_source=google&utm_medium=cpc&utm_term=unpaid&utm_content=test_baasreact&utm_campaign=test_baasreact">siin</a>
            </div>
        )
    }
    if (correctAnswers < 6) {
        return (
            <div>
                Vastasid rohkem kui pooltele küsimustele õieti. Ilmselt võiksid tulla Reacti kursusele.
                Kui kahtled, võid kaaluda siiski alustada veebiarenduse algkursusest <br />

                Tutvu veebiarenduse algkursusega lähemalt <a href="https://nort.ee/koolitused/moodulkoolitus-baasreact/?utm_source=google&utm_medium=cpc&utm_term=unpaid&utm_content=test_baasreact&utm_campaign=test_baasreact">siin</a><br />
                Reacti kursuse kohta saab täpsemat infot  <a href="https://nort.ee/koolitused/react/?utm_source=google&utm_medium=cpc&utm_term=unpaid&utm_content=test_react&utm_campaign=test_react">siin</a><br />
            </div>
        )
    }

    return (
        <div>
            Vastasid küsimustiku küsimustikele hästi. Oled kindlasti valmis alustama Reacti kursusega!
            <br />
            Reacti kursuse kohta saab täpsemat infot  <a href="https://nort.ee/koolitused/react/?utm_source=google&utm_medium=cpc&utm_term=unpaid&utm_content=test_react&utm_campaign=test_react">siin</a><br />
        </div>
    )
}

export default Evaluation