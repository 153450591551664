import {useNavigate} from "react-router-dom";
function Error({clearErrorMessage}) {
    const navigate = useNavigate();
    
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-6 align-self-center">
                    <div className="section-heading review">
                        <h6>Test project by Andres Järviste</h6>
                        <h4><em>Crikey!</em> something went wrong</h4>
                        <div>
                            <p>Lets try again</p>
                            <button 
                                onClick={()=> {
                                    navigate("/",{replace: true})
                                    clearErrorMessage()
                                }} 
                                className="main-action-button"
                            >
                                Restart the test
                            </button>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    )
}
export default Error