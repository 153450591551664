import Container from 'react-bootstrap/Container';
import Intro from './pages/Intro';
import Review from './pages/Review'
import Error from './pages/Error'

import Question from './pages/Question';
import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState({})
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [correctAnswers, setCorrectAnswers] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const clearErrorMessage = () => {
	setErrorMsg('')
  }

  const readQuestions = async () => {
	setAnswers({})
	setQuestions([])
	setLoading(true)
	setLoaded(false)
	clearErrorMessage()
    const response = await fetch("/api/questions");
	if (!response.ok) {
		setErrorMsg('Error fetching questions');
		setLoading(false)
		return;
	}
    const result = await response.json()
    setLoaded(true)
	setLoading(false)
    setQuestions(result);
  }

  const answerQuestion = (questionId, answerId) => {
	setAnswers((prev) => ({ ...prev, [questionId]: answerId}));
  }

  const submitAnswers = async () => {
    console.log("Submitting answers ...")
	setLoading(true)
	clearErrorMessage()
	const answersArray = Object.keys(answers).map((questionId) => ({
		questionId, answerId: answers[questionId] 
	}))
	const response = await fetch( "/api/answer", {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(answersArray)
		}
	)
	if (!response.ok) {
		setErrorMsg('Error fetching questions');
		setLoading(false)
		return;
	}

	const result = await response.json()
	if (!result.correctAnswers) {
		setErrorMsg('Can not find number of correct answers in response');
		console.log('Can not find number of correct answers in response')
		console.log(result)
		setLoading(false)
		return;
	}
	setCorrectAnswers(result.correctAnswers)
	setLoading(false)
  }

  return (
  <Router>
    <Container className="p-3">
    <Routes>
      <Route exact path="/" element={
        <Intro
          readQuestions={readQuestions}
        />
      } />
      <Route path="/question/:questionNo" element={
        <Question
          questions={questions}
          answers={answers}
          loaded={loaded}
          loading={loading}
          submitAnswers={submitAnswers}
		  answerQuestion={answerQuestion}
		  errorMsg={errorMsg}
        />
      } />
      <Route path="/conclusion" element={
		<Review
			correctAnswers={correctAnswers}
			loading={loading}
			errorMsg={errorMsg}
		/>
		} />
      <Route path="/error" element={<Error clearErrorMessage={clearErrorMessage}/>} />
    </Routes>
    <div className='row'>
      <div className="col-lg-12">
          <p className='copyright'>Design: <a rel="noreferrer" href="https://templatemo.com" target="_blank">TemplateMo</a>
          </p>
      </div>
    </div>
  </Container>
  </Router>

)};

export default App;